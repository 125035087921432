import React, { useState, useEffect } from "react";
import { Grid, RadioGroup, FormControlLabel, useRadioGroup, Radio, Divider, Tabs, Tab, useTheme, useMediaQuery, Box, Checkbox } from "@mui/material";
import { FormTitleLabel, SectionLabel } from "./Labels";
import { styled } from "@mui/material/styles";
import { isValidEmailAddress, isValidPhoneNumber } from "../utils/fieldValidators";
import useRequest from "../utils/hooks/useRequest";
import { postEmailDetails, postForSendSMS } from "../api/NotificationApi";
import useGlobalMessenger from "../utils/useGlobalMessenger";
import { useForm } from "../utils/hooks/useForm";
import Button from "../components/formFields/Button";
import { Paper } from "./containers";
import { TextField } from "./TextField";
import { getLabelUrl, getLocalize, getPlanProducts } from "../api/LabelApi";
import { SALES_UNITS } from "../utils/consts";

export default function LabelSelectForm() {
  const [formCriteria, setFormCriteria] = useState(null);
  const [salesUnits, setSalesUnits] = useState([
    SALES_UNITS.RESI,
    SALES_UNITS.MOB,
    SALES_UNITS.SMB
  ]);
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedSalesUnit, setSelectedSalesUnit] = useState(
    SALES_UNITS.RESI.code
  );
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPlanProduct, setSelectedPlanProduct] = useState('');
  const [productRef, setProductRef] = useState('');
  const [planProducts, setPlanProducts] = useState([]);
  const [selectedPlanConnectionType, setSelectedPlanConnectionType] =
    useState(null);
  const [selectedPlanRegion, setSelectedPlanRegion] = useState(null);
  const [localizedPlans, setLocalizedPlans] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("EN");
  const [selectedCustomerType, setSelectedCustomerType] = useState("ADD");
  const [labelUrl, setLabelUrl] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [emailId, setEmailId] = useState(null);
  const [zipCode, setZipCode] = useState(null);
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState(null);
  const [sendClicked, setSendClicked] = useState(false);
  const [postEmailResponse, setPostEmailResponse] = useState(null);
  const [postSmsResponse, setPostSmsResponse] = useState(null);

  const [localizeResponse, setLocalizeResponse] = useState(null);
  const [labelResponse, setLabelResponse] = useState(null);
  const [planProductsResponse, setPlanProductsResponse] = useState(null);
  const [addressList, setAddressList] = useState([]);
  const [responseError, setResponseError] = useState();

  const [selectTab, setSelectedTab] = React.useState(0);
  const [isLocalized, setIsLocalized] = React.useState(false);
  const [isLabelLoaded, setIsLabelLoaded] = React.useState(false);
  const { setGlobalMessage } = useGlobalMessenger();
  const [isResiOrSMB, setIsResiOrSMB] = React.useState(true);
  const [sendAsTransactional, setSendAsTransactional] = useState(false);
  const [isResi, setIsResi] = React.useState(true);

  const validateAddressLines = (input) => {
    const addressRegex = /^[a-zA-Z0-9 .'\-\#@%&\\/]*$/;

    return addressRegex.test(input);
  };

  const validateZipCode = (input) => {
    const zipRegex = /^[0-9]{5}$/;

    return zipRegex.test(input);
  };

  const initialFormValues = {
    emailAddress: "",
    addressLine1: "",
    addressLine2: "",
    zipCode: ""
  };

  const validateSearchparams = (fieldValues = values) => {
    let temp = { ...errors };

    if (!fieldValues.addressLine1) {
      temp.addressLine1 = "Required.";
    } else if (!validateAddressLines(fieldValues.addressLine1)) {
      temp.addressLine1 = "Invalid input.";
    } else {
      temp.addressLine1 = "";
    }

    if (fieldValues.addressLine2 && !validateAddressLines(fieldValues.addressLine2)) {
      temp.addressLine2 = "Invalid input.";
    } else {
      temp.addressLine2 = "";
    }


    if (!fieldValues.zipCode) {
      temp.zipCode = "Required.";
    } else if (!validateZipCode(fieldValues.zipCode)) {
      temp.zipCode = "Invalid input.";
    } else {
      temp.zipCode = "";
    }

    setErrors({
      ...temp
    })
    if (fieldValues == values)
      return Object.values(temp).every(x => x == "");
  }

  const getLocalizeReq = useRequest(getLocalize);
  const getLabelReq = useRequest(getLabelUrl);
  const postEmailDetailsReq = useRequest(postEmailDetails);
  const postSendSMSReq = useRequest(postForSendSMS);
  const getPlanProductsReq = useRequest(getPlanProducts);

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialFormValues,
    false,
    validateSearchparams
  );

  const handleSendLabelTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
  ))(({ theme, checked }) => ({
    ".MuiFormControlLabel-label": checked
      ? {
        color: "#003561",
        fontSize: "15px",
        textAlign: "left",
      }
      : {
        color: "#7f7f7f",
        fontSize: "15px",
        textAlign: "left",
      },
  }));

  function RadioFormControlLabel(props) {
    const radioGroup = useRadioGroup();
    let checked = false;
    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }
    return <StyledFormControlLabel checked={checked} {...props} />;
  }


  const handleImageError = () => {
    setImageError(true);
  }

  const processLocalizeResponse = (response) => {
    setLocalizeResponse(response);
  }

  const handleLocalizationClick = (e) => {
    e.preventDefault();
    if (isResiOrSMB) {
      if (validateSearchparams(values)) {
        setAddressList(null);
        setResponseError(null);
        if (isResi) {
          getLocalizeReq.request({ salesUnit: selectedSalesUnit, line1: values.addressLine1, line2: values.addressLine2, zip: values.zipCode, customerType: selectedCustomerType }, processLocalizeResponse);
        } else {
          getLocalizeReq.request({ salesUnit: selectedSalesUnit, line1: values.addressLine1, line2: values.addressLine2, zip: values.zipCode }, processLocalizeResponse);
        }
      }
    } else {
      setAddressList(null);
      setResponseError(null);
      getLocalizeReq.request({ salesUnit: selectedSalesUnit }, processLocalizeResponse);
    }
  }

  useEffect(() => {
    if (localizeResponse?.data) {
      var tempData = localizeResponse.data;
      setLocalizedPlans(tempData);
      if (tempData.length > 0) {
        setSelectedPlan(tempData[0].planCode);
        setSelectedPlanConnectionType(tempData[0].connectionType);
        setSelectedPlanRegion(tempData[0].region)
        setIsLocalized(true);
        if (isResi) {
          getPlanProductsReq.request({ salesUnit: selectedSalesUnit, planCode: tempData[0].planCode, locRef: tempData[0].region, customerType: selectedCustomerType }, setPlanProductsResponse);
        } else {
          getPlanProductsReq.request({ salesUnit: selectedSalesUnit, planCode: tempData[0].planCode, locRef: tempData[0].region }, setPlanProductsResponse);
        }
      }
    } else if (localizeResponse?.errors) {
      setIsLocalized(false);
      setGlobalMessage({ text: localizeResponse?.errors?.message, variant: "error" });
    }
  }, [localizeResponse]);


  const handleGetLabelClick = (e) => {
    e.preventDefault();
    getLabelReq.request(selectedSalesUnit, selectedPlan, selectedPlanConnectionType, selectedLanguage, selectedPlanRegion, productRef, setLabelResponse);
  };

  useEffect(() => {
    if (planProductsResponse?.data) {
      var tempData = planProductsResponse.data.data;
      setPlanProducts(tempData);
      setIsLocalized(true);
      if (tempData?.length > 0) {
        setSelectedPlanProduct(tempData[0]?.productDescript);
        setProductRef(tempData[0]?.productRef);
      }
    } else if (planProductsResponse?.message) {
      setPlanProducts([]);
      setIsLocalized(true);
      setGlobalMessage({ text: planProductsResponse?.message, variant: "error" });
    }
  }, [planProductsResponse]);

  useEffect(() => {
    if (labelResponse?.data) {
      setIsLabelLoaded(true);
      setLabelUrl(labelResponse.data);
      setImageError(false);
      // setSelectedConnectionType(localizeResponse.data?.conn === "ASYNC" ? 'A' : 'S');
      // setIsLocalized(true);
    } else if (labelResponse?.errors) {
      setIsLocalized(false);
      setGlobalMessage({ text: labelResponse?.errors?.message, variant: "error" });
    }
  }, [labelResponse]);

  const handleEmailChange = (e) => {
    const { value } = e.target;
    const temp = { ...errors };
    if (!value) {
      temp.emailId = "This field is required"
    } else if (!isValidEmailAddress(value)) {
      temp.emailId = "Please validate email"
    } else {
      temp.emailId = "";
    }
    setErrors(temp);
    setEmailId(value);
  };

  const handleSmsChange = (e) => {
    const { value } = e.target;
    const temp = { ...errors };

    if (!value) {
      temp.customerPhoneNumber = "This field is required";
    } else if (!isValidPhoneNumber(value)) {
      temp.customerPhoneNumber = "Invalid phone number"
    } else {
      temp.customerPhoneNumber = "";
    }
    setErrors(temp);
    setCustomerPhoneNumber(value);
  }

  const onReset = () => {
    setIsLabelLoaded(false);
    setIsLocalized(false);
    setSelectedLanguage('EN');
    setEmailId('');
    setSelectedTab(0)
    setSendClicked(false);
    setCustomerPhoneNumber("");
    setSelectedPlanProduct('');
    setSelectedPlan(null);
    setLocalizedPlans(null);
    setPlanProducts([]);
    resetForm();
  }

  const sendLabelViaEmail = async () => {
    setSendClicked(true);
    if (!isValidEmailAddress(emailId)) {
      return;
    }
    let payload = {
      toAddress: emailId,
      salesUnit: selectedSalesUnit,
      plan: selectedPlan,
      conn: selectedPlanConnectionType,
      lang: selectedLanguage,
      address: {
        line1: values.addressLine1,
        line2: values.addressLine2,
        zip: values.zipCode
      },
      region: selectedPlanRegion,
      productRef: productRef
    }

    postEmailDetailsReq.request(payload, setPostEmailResponse);
  }

  useEffect(() => {
    if (postEmailResponse?.data && !postEmailResponse?.errors) {
      setGlobalMessage({ text: "Email sent successfully" });
      setEmailId("");
    } else if (postEmailResponse?.errors) {
      setGlobalMessage({ text: postEmailResponse?.errors.message, variant: "error" });
    }
  }, [postEmailResponse]);

  const sendLabelViaSMS = async () => {
    setSendClicked(true);
    if (!isValidPhoneNumber(customerPhoneNumber)) {
      return;
    }
    const payload = {
      toNumber: customerPhoneNumber,
      salesUnit: selectedSalesUnit,
      plan: selectedPlan,
      conn: selectedPlanConnectionType,
      lang: selectedLanguage,
      address: {
        line1: values.addressLine1,
        line2: values.addressLine2,
        zip: values.zipCode
      },
      region: selectedPlanRegion,
      productRef: productRef
    }
    postSendSMSReq.request(payload, setPostSmsResponse);
  }

  useEffect(() => {
    if (postSmsResponse?.data && !postSmsResponse?.errors) {
      const responseData = postSmsResponse.data;
      setGlobalMessage({ text: responseData.message });
      setCustomerPhoneNumber("");
    } else if (postSmsResponse?.errors) {
      setGlobalMessage({ text: postSmsResponse?.errors?.message, variant: "error" });
    }
  }, [postSmsResponse]);

  useEffect(() => {
    setCustomerPhoneNumber("");
    setEmailId("");
  }, [selectTab])

  const handleSalesUnitChange = (e) => {
    const { value } = e.target;
    if (value == "MOB") {
      setIsResiOrSMB(false);
      setIsResi(false);
    } else if (value == "RESI") {
      setIsResiOrSMB(true);
      setIsResi(true);
    } else {
      setIsResiOrSMB(true);
      setIsResi(false);
    }
    setSelectedSalesUnit(value);
  };

  const handlePlanChange = (e) => {
    const { value } = e.target;
    var tempPlan = localizedPlans.find(a => a.planCode === value);
    setSelectedPlan(tempPlan.planCode);
    setSelectedPlanConnectionType(tempPlan.connectionType);
    setSelectedPlanRegion(tempPlan.region);
    if (isResi) {
      var planPrd = getPlanProductsReq.request({ salesUnit: selectedSalesUnit, planCode: tempPlan.planCode, locRef: tempPlan.region, customerType: selectedCustomerType }, setPlanProductsResponse);
      setSelectedPlanProduct(planPrd.data);
    } else {
      var planPrd = getPlanProductsReq.request({ salesUnit: selectedSalesUnit, planCode: tempPlan.planCode, locRef: tempPlan.region }, setPlanProductsResponse);
      setSelectedPlanProduct(planPrd.data);
    }

  };

  const handleZipChange = (e) => {
    const value = e.target.value;

    if (value.length > 5) {
      e.preventDefault();
    } else {
      handleInputChange(e);
    }
  }


  const handlePlanProducts = (event) => {
    const value = event?.target?.value;
    setSelectedPlanProduct(value);
    var product = planProducts.filter(item => item.productDescript === value);
    setProductRef(product[0]?.productRef);

  };


  const headerSection = (
    <Grid item xs={12} sx={{ paddingTop: 3 }}>
      <h1 style={{ textAlign: "left" }}>Broadband Label</h1>
    </Grid>
  );

  const localizeSection = (
    <Paper sx={{ padding: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={2}>
          <FormTitleLabel sx={{ padding: "0 !important" }}>PLAN TYPE</FormTitleLabel>
        </Grid>
        <Grid container item xs={12} sm={10} spacing={1} sx={{ textAlign: "left" }}>
          <RadioGroup
            onChange={handleSalesUnitChange} row name="radio-sales-unit" value={selectedSalesUnit}>
            {salesUnits &&
              salesUnits.map((s) => (
                <Grid item xs={12} sm={"auto"}>
                  <RadioFormControlLabel disabled={isLocalized} value={s.code} control={<Radio />} label={s.desc}
                  />
                </Grid>
              ))}
          </RadioGroup>
        </Grid>
      </Grid>
      {isResi &&
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item xs={12} sm={12} sx={{ marginBottom: '20px' }}>
            <Divider orientation="horizontal" flexItem sx={{ marginTop: 2 }} />
          </Grid>
          <Grid container item xs={12} sm={10} spacing={1} sx={{ textAlign: "left", marginLeft: '115px' }} >

            <RadioGroup
              onChange={(e) => {
                setSelectedCustomerType(e.target.value);
              }} name="radio-customerType-selection" defaultValue="ADD"
            >
              <Grid container item xs={12} sm={"auto"} direction="row" >
                <RadioFormControlLabel disabled={isLocalized} value="ADD" control={<Radio />} label="Add Internet Services" />
                <RadioFormControlLabel disabled={isLocalized} value="MODIFY" control={<Radio />} label="Modify Internet Services" />
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>}
      <Divider orientation="horizontal" flexItem sx={{ marginTop: 2 }} />
      {isResiOrSMB && (
        <Grid container rowSpacing={1} sx={{ marginTop: 2 }}>
          <Grid item xs={12} sm={2} sx={{ pr: { sm: 2 }, display: { sm: "none", md: "flex" }, marginBottom: 2, }}>
            <FormTitleLabel>ADDRESS</FormTitleLabel>
          </Grid>
          <Grid container item xs={12} sm={10} spacing={1}>
            <Grid item xs={12} sm={"auto"}>
              <TextField
                name="addressLine1"
                placeholder="Street Address"
                onChange={handleInputChange}
                value={values.addressLine1}
                error={errors.addressLine1}
                helperText={errors.addressLine1}
                size={"small"}
                sx={{ marginBottom: 2, width: "100%" }}
              />
            </Grid>
            <Grid item xs={6} sm={"auto"} sx={{ pr: { xs: 1, sm: 0 } }}>
              <TextField
                name="addressLine2"
                placeholder="Apt/Unit"
                onChange={handleInputChange}
                value={values.addressLine2}
                error={errors.addressLine2}
                helperText={errors.addressLine2}
                size={"small"}
                sx={{
                  marginBottom: 2,
                  width: { xs: "100%", sm: 100 },
                  marginRight: { sx: 2 },
                }}
              />
            </Grid>
            <Grid item xs={6} sm={"auto"} sx={{ pl: { xs: 1, sm: 0 } }}>
              <TextField
                name="zipCode"
                placeholder="Zip code"
                onChange={handleInputChange}
                value={values.zipCode}
                error={errors.zipCode}
                helperText={errors.zipCode}
                size={"small"}
                sx={{ marginBottom: 2, width: { xs: "100%", sm: 150 } }}
              />
            </Grid>
            <Grid item xs={12} sm={"auto"}>
              {(!isLocalized) && <Button text="LOCALIZE" onClick={handleLocalizationClick} sx={{ height: "40px", width: { xs: "100%", sm: "auto" } }} />}
            </Grid>
          </Grid>
        </Grid>
      )}
      {!isResiOrSMB && (
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 2 }}>
          <Button text="GET PLANS" disabled={isLocalized} onClick={handleLocalizationClick} sx={{ height: "40px", width: { xs: "100%", sm: "126px" } }} />
        </Grid>
      )}
    </Paper>
  );

  const planSection = (
    <Paper sx={{ padding: 2, marginTop: 2 }}>
      <Grid container rowSpacing={3}>
        <Grid container item xs={12} sm={5} spacing={1} sx={{ paddingRight: { sm: 1 } }}>
          <Grid item xs={12}>
            <SectionLabel>PLAN</SectionLabel>
            <RadioGroup onChange={handlePlanChange} name="radio-plans" value={selectedPlan}
            >
              {localizedPlans?.map((p) => (
                <RadioFormControlLabel value={p.planCode} control={<Radio />} label={p.planName} />
              ))}
            </RadioGroup>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={2} container direction="row" justifyContent="center" alignItems="center">
          <Divider
            orientation={isXsScreen ? "horizontal" : "vertical"}
            sx={{ width: { xs: "100%", sm: "auto" } }}
          />
        </Grid>
        <Grid container item xs={12} sm={5} spacing={1} sx={{ paddingLeft: { sm: 1 } }}>
          <Grid item xs={12}>
            <SectionLabel>OFFERS</SectionLabel>
            <RadioGroup value={selectedPlanProduct} name="radio-plans-products" onChange={handlePlanProducts}>
              {planProducts.map((offer, index) => (
                <FormControlLabel key={index} value={offer.productDescript} control={<Radio />} label={offer.productDescript} />
              )
              )}
            </RadioGroup>
          </Grid>
        </Grid>
      </Grid>
    </Paper >
  );

  const languageSection = (
    <Paper sx={{ padding: 2, marginTop: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={2}>
          <SectionLabel>LANGUAGE</SectionLabel>
        </Grid>
        <Grid container item xs={12} sm={10} spacing={1} sx={{ textAlign: "left" }}>
          <RadioGroup
            onChange={(e) => {
              setSelectedLanguage(e.target.value);
            }} name="radio-language-selection" defaultValue="EN"
          >
            <Grid container direction="row" sx={{ marginLeft: "45px" }}>
              <RadioFormControlLabel value="EN" control={<Radio />} label="English" />
              <RadioFormControlLabel value="ES" control={<Radio />} label="Español" />
            </Grid>
          </RadioGroup>
        </Grid>
      </Grid >
    </Paper >
  );



  const resetButtonSection = (
    <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: 2 }}>
      <Button text="SEE RESULTS" onClick={handleGetLabelClick} sx={{ width: { xs: "100%", sm: "auto" }, marginRight: { sm: 3 } }} />
      <Button text="RESET" variant="outlined" sx={{ width: { xs: "100%", sm: "auto" }, marginTop: { xs: 2, sm: 0 } }} onClick={() => { onReset(); }} />
    </Grid>
  );

  const sendLabelSection = (
    <Paper sx={{ padding: 2, marginTop: 2, paddingBottom: 2 }}>
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <SectionLabel>SEND LABEL</SectionLabel>
        </Grid>
        <Grid item xs={12}>
          <Tabs centered value={selectTab} onChange={handleSendLabelTabChange}>
            <Tab label={<SectionLabel>EMAIL</SectionLabel>} />
            <Tab label={<SectionLabel>SMS</SectionLabel>} />
          </Tabs>
        </Grid>
        <Divider variant="middle" sx={{ borderBottomWidth: 2 }} />
        <Grid container item sx={{ alignItems: "center" }}>
          <Grid item xs="auto" sx={{ display: { xs: "none", sm: "flex" } }}>
            <span>{selectTab !== 1 ? "Send Email:" : "Send SMS:"}</span>
          </Grid>
          <Grid item xs={12} sm="auto" sx={{ paddingLeft: { sm: 2, xs: 0 } }}>
            {selectTab !== 1 ? (
              <>
                <TextField
                  id="emailAddress"
                  value={emailId}
                  onChange={handleEmailChange}
                  size={"small"}
                  placeholder="Email Address"
                  error={sendClicked && errors.emailId}
                  helperText={sendClicked ? errors.emailId : ""}
                  sx={{ width: { xs: "100%", sm: "auto" }, marginRight: { sm: 2 } }} />
                <Button text="SEND" onClick={sendLabelViaEmail} sx={{ height: "40px", width: { xs: "100%", sm: "auto" }, marginTop: { xs: 2, sm: 0 } }} />
              </>
            ) : (
              <>
                <TextField
                  id="phoneNumber"
                  value={customerPhoneNumber}
                  onChange={(e) => {
                    setCustomerPhoneNumber(e.target.value);
                  }}
                  size={"small"}
                  placeholder="Phone Number"
                  sx={{ width: { xs: "100%", sm: "auto" }, marginRight: { sm: 2 } }}
                //    error={sendClicked && errors.emailId} //TODO update with phone logics
                //    helperText={sendClicked ? errors.emailId : ""}
                />
                <Button text="SUBMIT" onClick={sendLabelViaSMS} sx={{ height: "40px", width: { xs: "100%", sm: "auto" }, marginTop: { xs: 2, sm: 0 }, marginRight: { xs: 2, sm: 2 } }} />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );

  const imageSection = (
    <Paper sx={{ padding: 2 }}>
      {!imageError && labelUrl ? (
        <img src={labelUrl} width="100%" alt="" onError={handleImageError} />
      ) : (
        <p>Image not available. Please select again</p>
      )}
    </Paper>
  );

  return (
    <Box>
      {headerSection}
      <Grid container spacing={2} sx={{ marginBottom: 10 }}>
        <Grid item xs={12} sm={8} sx={{ height: "100%" }}>
          {localizeSection}
          {isLocalized && planSection}
          {isLocalized && languageSection}
          {isLocalized && resetButtonSection}
          {isLabelLoaded && sendLabelSection}
        </Grid>
        <Grid item xs={12} sm={4} sx={{ paddingBottom: 5 }}>
          {isLabelLoaded && imageSection}
        </Grid>
      </Grid>
    </Box>
  );
}
