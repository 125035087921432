import React from 'react'
import { Button as MuiButton } from '@mui/material';


function Button(props) {
    const { text, size, color, variant, onClick, ...other } = props

    return (
        <MuiButton
                   variant={variant || "contained"}
                   size={size || "small"}
                   color={color || "primary"}
                   onClick={onClick}
                   sx={{ fontSize:"16px", margin:"1em", height: '2.5em'}}
                   {...other}>
            {text}
        </MuiButton>
    )
}

export default Button;