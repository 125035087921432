import axios from "axios";
import { getApiOptions } from "../utils/apiHelpers";
import URLs from "../utils/urls";
import { HTTP_VERB } from "../utils/consts";

export async function getLocalize(params, setResponse) {
    var urlEndpoint = `${URLs.PLANS}`;
    const ops = {
        method: HTTP_VERB.GET,
        url: urlEndpoint,
        ...getApiOptions(),
        params: params
    };
    try {
        setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data);
        } else {
            setResponse(error);
        }
    }
}

export async function getLabelUrl(salesUnit, plan, conn, lang, region, productRef, setResponse) {
    const urlEndpoint = `${URLs.LABEL}/png?salesUnit=${salesUnit}&plan=${plan}&conn=${conn}&lang=${lang}&region=${region}&productRef=${productRef}`;
    const ops = {
        method: HTTP_VERB.GET,
        url: urlEndpoint,
        ...getApiOptions(),
    };
    try {
        setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data);
        } else {
            setResponse(error);
        }
    }
}

export async function getPlanProducts(params, setResponse) {
    const urlEndpoint = `${URLs.PLAN_PRODUCTS}`;
    const ops = {
        method: HTTP_VERB.GET,
        url: urlEndpoint,
        ...getApiOptions(),
        params: params
    };
    try {
        const response = await axios(ops);
        setResponse(response);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data)
        } else {
            setResponse(error);
        }
    }
}