import axios from "axios";
import { getApiOptions } from "../utils/apiHelpers";
import URLs from "../utils/urls";
import {HTTP_VERB} from "../utils/consts";


export async function postEmailDetails(payload, setResponse) {
    const ops = {
        method: HTTP_VERB.POST,
        url: URLs.EMAIL,
        ...getApiOptions(),
        data: payload
    };
    try {
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data);
        } else {
            setResponse(error);
        }
    }
}


export async function postForSendSMS(payload, setResponse) {
    const ops = {
        method: HTTP_VERB.POST,
        url: URLs.SMS,
        ...getApiOptions(),
        data: payload
    };
    try {
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data);
        } else {
            setResponse(error);
        }
    }
}