export const isValidEmailAddress = (input) => {
    const emailCheckRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
    if(!input || input.length === 0) {
        return false;
    }
    return emailCheckRegex.test(input);
};

export const isValidPhoneNumber = (input) => {
    const phoneNumberRegex = /(\d{3}[-]\d{3}[-]\d{4})|\d{10}|([(]\d{3}[)]\d{3}[-]\d{4})|(\d{3}[\s]\d{3}[\s]\d{4})/;
    if(!input || input.length < 10){
        return false;
    }
    return phoneNumberRegex.test(input);
}