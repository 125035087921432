import { useContext, useState } from "react";

import globalContext from "../../context/globalContext/globalContext";
const useRequest = (apiFunc, showProgressGlobally = true) => {
    const { incrementOpenRequestCount, decrementOpenRequestCount } = useContext(globalContext);
    const [inprogress, setInprogress] = useState(false);

    const request = async (...args) => {
        setInprogress(true);
        if (showProgressGlobally) {
            incrementOpenRequestCount();
        }
        await apiFunc(...args);
        setInprogress(false);
        if (showProgressGlobally) {
            decrementOpenRequestCount();
        }
    };
    return {
        inprogress,
        request
    };
}

export default useRequest;