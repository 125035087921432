import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, responsiveFontSizes, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';

const baseTheme = responsiveFontSizes(createTheme({
  typography: {
    fontFamily: 'SpectrumSans',
    h4: {
      fontWeight: 500,
      marginTop: 50,
    },
    button: {
      textTransform: 'none',
      fontWeight: 'normal'
    }
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        fontSize: '18px',
        fontWeight: 600,
        color: '#f44336',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
        backgroundColor: 'rgba(0, 0, 0, 0.87)',
      },
      arrow: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
  },
}));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     
      <ThemeProvider theme={baseTheme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
