export const resetError = {
    isError: false,
    error: {
        statusCode: '',
        errorMessage: '',
        errorRes: {},
    },
    isLoading: false,
};

export const defaultUrlInfo = {
    isError: false,
    isLoading: false,
    error: {
        statusCode: '',
        errorMessage: '',
        errorRes: {},
    },
    pdf: '',
    html: ''
};
