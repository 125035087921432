import styled from "@emotion/styled";
import { Typography, FormLabel as MuiFormLabel } from "@mui/material"; 


export const FormLabel = styled(MuiFormLabel)({
    fontSize: '16px',
    fontWeight: "normal",
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#003561',
    height: '30px',
    padding: '.1em 0'
});


export const FormTitleLabel = styled(MuiFormLabel)({
    fontSize: '15px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#003561',
    height: '20px',
    padding: '1.1em'
});

export const SectionLabel = styled(MuiFormLabel)({
    fontSize: '15px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#0078b3',
    height: '30px',
    padding: '0'
});
