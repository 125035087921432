import React from 'react';

import { defaultUrlInfo } from "../../utils/globalStateHelper";

export const getDefaultContext = () => ({
    urlInfo: {
        ...defaultUrlInfo,
    },
    openRequests: 0
});

const setDefaultContext = {
    setUrlInfo: () => {/* Implemented in reducer */ },
    incrementOpenRequestCount: () => {/* Implemented in reducer */ },
    decrementOpenRequestCount: () => {/* Implemented in reducer */ },
};

export default React.createContext({ ...getDefaultContext(), ...setDefaultContext });