import * as React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Toolbar, Grid } from '@mui/material';
import Logo from '../assets/spectrum-logo-white.svg';

const AppBarWrapper = styled(MuiAppBar, {})(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
}));

export default function Header() {

    return (
        <Box>
            <CssBaseline />
            <AppBarWrapper position="fixed">
                <Toolbar sx={{
                    bgcolor: '#001b33'
                }}>
                    <a href='/'>
                        <img src={Logo} style={{ paddingLeft: '5.4em' }} alt='Spectrum' />
                    </a>
                </Toolbar>
            </AppBarWrapper>
        </Box>
    );
}