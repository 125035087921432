import './App.css';
import { useContext } from 'react';
import Header from './components/header';
import LabelSelectForm from './components/LabelSelectForm';
import Footer from './components/footer';
import {Grid, GlobalStyles, Container, Stack, CircularProgress,Backdrop} from '@mui/material';
import globalContext from "./context/globalContext/globalContext";
import DataContexts from "./context/dataContexts";


function AppProgressBar() {
    const { openRequests } = useContext(globalContext);
    return (<>
        {openRequests > 0 &&
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        }
    </>);
}
function App() {
  return (
    <div className="App">
        <DataContexts>
              <Header />
              <Container maxWidth >
                <Container maxWidth="lg" sx={{ marginTop: "3.5em" }}>
                  <GlobalStyles styles={{ td: { fontFamily: "SpectrumSans !Important", color: '#005a84 !Important' } }} />
                  <LabelSelectForm />
                </Container>
                <AppProgressBar />
              </Container>
              <Footer />
        </DataContexts>
    </div>
  );
}

export default App;
