import * as React from 'react';
import { Box } from '@mui/material';
import Logo from '../assets/spectrum-logo-white.svg';

export default function Footer() {
    return (
        <Box sx={{
            bgcolor: '#001b33',
            pr: '24px',
            height: '2em',
            maxHeight: '2em',
            position: 'fixed',
            bottom: 0,
            width: '100%',
        }}/>
    );
}