import { getDefaultContext } from './globalContext';
import {
    SET_URL_INFO,
    RESET,
    INCREMENT_REQUEST_COUNT,
    DECREMENT_REQUEST_COUNT
} from './globalActionTypes';

const globalReducer = (state, action) => {
    const { type, payload = {} } = action;
    switch (type) {
        case SET_URL_INFO:
            return {
                ...state,
                urlInfo: {
                    ...state.urlInfo,
                    ...payload
                }
            };
        case RESET:
            return { ...state, ...getDefaultContext() };
        case INCREMENT_REQUEST_COUNT:
            return { ...state, openRequests: state.openRequests + 1 }
        case DECREMENT_REQUEST_COUNT:
            let newRequestsCount = state.openRequests - 1;
            if (newRequestsCount < 0) {
                newRequestsCount = 0;
            }
            return { ...state, openRequests: newRequestsCount };
        default:
            return state;
    }
};

export { globalReducer as default };
